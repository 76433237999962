import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

const LottieWrapper = ({
  animationData,
  height = 400,
  width = "100%",
  loop = true,
  autoplay = true,
  isClickToPauseDisabled = false,
  isStopped = false,
  isPaused = false,
  speed = 1,
  direction = 1,
  segments = null,
  eventListeners = null,
  style = {},
  className = "",
}) => {
  return (
    <Lottie
      options={{
        loop,
        autoplay,
        animationData,
        isClickToPauseDisabled,
        isStopped,
        isPaused,
        speed,
        direction,
        segments,
        eventListeners,
      }}
      height={height}
      width={width}
      style={style}
      className={className}
    />
  );
};

export default LottieWrapper;
